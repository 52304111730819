a.href {
	text-decoration: none;
}

.Contact {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



h2 {
	padding: 12px;
  align-items: center;
}

h4 {
	bottom: 0;
	position: static;
	text-align: center;
	color: aliceblue;
  margin-left: auto;
  margin-right: auto; 
  min-width: 300px;
  max-width: 100%;
  height: 30px;   /* Height of the footer */
  background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
 
}

.Sakima {
  font-size: 1.4em;
  text-align: center;
  vertical-align: middle;
	color: aliceblue;
  background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
	height: 30px;
	font-family: sans-serif;
	width: auto;
	margin-left: auto;
  margin-right: auto; 
	
	
}

.about {
  text-decoration: none;
}

.Navbar {
	font-family: sans-serif;
	color: #fff; background: black;
	font-weight: 600;
	text-decoration: none;
	justify-content: center;
  align-items: center;
}

@media (min-width: 475px) {
  .Sakima, .Navbar, h4  {
        max-width: 474px;
      
    }
    
  }

  @media (min-width: 640px) {
    .Sakima, .Navbar, h4  {
        max-width: 640px;
       
    }
    
  }

  @media (min-width: 768px) {
    .Sakima, .Navbar, h4  {
        max-width: 768px;
        
    }
    
  }

  @media (min-width: 1024px) {
    .Sakima, .Navbar, h4  {
        max-width: 1024px;
        
    }
    
  }

  @media (min-width: 1280px) {
    .Sakima, .Navbar, h4  {
        max-width: 1280px;
       
    }
    
  }

  @media (min-width: 1536px) {
    .Sakima, .Navbar, h4  {
        max-width: 1536px;
        
    }
    
  }



