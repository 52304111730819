* {
  box-sizing:border-box;
  margin: 0;
  padding: 0;
}

a {
	text-decoration: none;
};

