* {
    background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
    border-spacing: 5vh;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    min-height: 600px;
    min-width: 300px;
    height: 700px;
    width: 800px;
    border: 3px solid;
    margin: auto;
    padding: 10px;
    display: flex;
    color: aliceblue;
    background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
    border-spacing: 5vh;
  }

  .container1.card {
    margin-right: auto;
    margin-left: auto;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-collapse: separate;  
    /* border-spacing: 50pt 1em;   */
    min-width: 300px;
    width: 800px;
    background-color: black;
    color: aliceblue;
    min-height: 27em;
    
   
  }

  @media (min-width: 320px) {
    .container, .Sakima, .bio, .container1.card   {
        max-width: 474px;
        justify-content: center;
        align-items: center;
        
      
    }
    
  }

  @media (min-width: 640px) {
    .container, .Sakima, .bio, .container1.card {
        max-width: 640px;
       
    }
    
  }

  @media (min-width: 768px) {
    .container, .Sakima, .bio, .container1.card  {
        max-width: 768px;
        
    }
    
  }

  @media (min-width: 1024px) {
    .container, .Sakima, .bio, .container1.card  {
        max-width: 1024px;
        
    }
    
  }

  @media (min-width: 1280px) {
    .container, .Sakima, .bio, .container1.card  {
        max-width: 1280px;
       
    }
    
  }

  @media (min-width: 1536px) {
    .container, .Sakima, .bio, .container1.card  {
        max-width: 1536px;
        
    }
    
  }

  .box, h8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    padding: 2px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

.dropdown > button {
    background-color: rgb(0, 34, 255);
}

.Kimo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 300px;
    box-shadow: 0 0 8px 8px white inset;
}

h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    color: aliceblue;
    background-color: rgb(0, 34, 255);
    padding: 8px;
    width: 130px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    border-radius: 90%;
}

.button1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    background-color: rgb(0, 34, 255);
    padding: 5px;
    width: 100px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    border-radius: 90%;
    float: right;
}

.btn.btn-primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    background-color: rgb(0, 34, 255);
    padding: 5px;
    width: 100px;
    font-size: 18px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    border-radius: 90%;
    float: left;
}

.Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
  .auger > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 275px;
      height: 300px;
      box-shadow: 0 0 8px 8px white inset;
      
  }
   
  h2 {
    padding-right: 5px;
   }
  
   .auger > p {
    font-size: medium;
   
   }

   .container > card {
    display: flex;
    justify-items: center;
    color: aliceblue;
    background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
    padding: 10px;

}

.card > img {
    display: flex;
    justify-items: center;
    color: aliceblue;
    /* width: 300px; */
    
}

.d-block w-100 {
    
    /* width:400px;
    max-height: 600px */

}

.carousel-indicators {
    width: 120px;
    border-radius: 70%;
    background:rgb(0, 34, 255);
    
  
    
}

.carousel-control-next-icon  {
    background:rgb(21, 0, 255);
    border-radius: 70%;
}

.carousel-control-prev-icon  {
    background:rgb(0, 34, 255);
    border-radius: 70%;
}

h3, p {
    color: aliceblue;
    text-align: center;

}

h8 {
    color: aliceblue;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* font-weight: 500; */

}

.card-body {
    height: 200px;
    margin-top: 0;
    background-image: radial-gradient(circle, #0022ff, #003ffe, #0052fa, #0061f4, #006eec, #007ced, #0089ed, #0095ea, #00a7f0, #00b8f2, #00c7f0, #00d6ec);
}

.card-title {
    color: aliceblue;
    text-decoration: bold;
}

